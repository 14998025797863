import * as qs from 'qs';
import * as actionTypes from '../consts/actionTypes';
import * as Sentry from '@sentry/react';
import { defaultSearchParams } from '../consts/defaultSearchParams';
import { filterParamsSchema } from '../consts/filterParamsSchema';
import { deepClone } from '../helpers/deepClone';
import { getSortingBasedOnMarket, Markets } from '../helpers/getSortingBasedOnMarket';
import { get, post } from '../helpers/http';
import { IsomorphicHelpers } from '../helpers/IsomorphicHelpers';
import { makeFilterParamsList, makeFilterParamsObject } from '../helpers/queryParams';
import { ICountryCode } from '../interfaces';
import { ICruiseFilterParamValue, ICruiseSearchApiObject } from '../interfaces/ISearchApi';
import { IFeatureFlag } from '../interfaces/IFeatureFlag';
import { IState } from '../reducers';
import { searchOverlayAction } from '../reducers/search';

const isDesktop = () => !IsomorphicHelpers.isServerSide && window.innerWidth >= 1200;

const CABIN_FILTER_PARAM_NAME = 'cabinKind';
const getCabinFilterParams = (paramList) => {
    // we need to set cabin filters as array to prevent
    // removing of multiple 'cabinKind' types in filterParamsList state
    // when we set filters inside reducers/search.ts
    return paramList?.reduce((activeFilters, element) => {
        if (element.paramName === CABIN_FILTER_PARAM_NAME) {
            activeFilters.push(element.paramValue);
        }
        return activeFilters;
    }, []);
};

export const setFilterParam = (
    filterParams: ICruiseFilterParamValue[],
    doSearch: boolean = false,
) => (dispatch, getState: () => IState) => {
    const activeList = getState().search.filterParamsListActive;
    const paramList = filterParams ?? activeList;

    paramList.forEach((filterParam) => {
        if (filterParam.paramName === CABIN_FILTER_PARAM_NAME) {
            let сabinFilterParams;
            if (filterParams && Array.isArray(filterParams[0]?.paramValue)) {
                // if we receive cabin filters directly as array in 'filterParams'
                // we should set them directly
                сabinFilterParams = filterParams[0].paramValue;
            } else {
                сabinFilterParams = getCabinFilterParams(paramList);
            }

            dispatch({
                type: actionTypes.setFilterParam,
                payload: {
                    paramName: CABIN_FILTER_PARAM_NAME,
                    paramValue: сabinFilterParams,
                },
            });
        } else {
            dispatch({ type: actionTypes.setFilterParam, payload: filterParam });
        }
    });
    const currentList = getState().search.filterParamsList;

    const list = filterParams ? currentList : activeList;

    return dispatch(updateFacets(list))
        .then(() => {
            if (doSearch) {
                doNewSearchViaUrl()(dispatch, getState);
            }
        })
        .catch((_) => {});
};

export const deleteFilterParam = (filterParam: ICruiseFilterParamValue) => (dispatch, getState) => {
    dispatch({ type: actionTypes.deleteFilterParam, payload: filterParam });
};

export const clearFilterParams = (fetchFacets = true, doSearch = false) => (dispatch, getState) => {
    dispatch({ type: actionTypes.clearFilterParams, payload: null });
    if (fetchFacets) {
        dispatch(updateFacets([]))
            .then(() => {
                if (doSearch) {
                    doNewSearchViaUrl()(dispatch, getState);
                }
            })
            .catch((_) => {});
    }
};

export const updateFacets = (filterList?: ICruiseFilterParamValue[]) => (
    dispatch,
    getState,
): Promise<any> => {
    const facetsUrl = `${getState().pageData.appConfig.apiHost}/cruises/facets`;
    const facetParams = getState().search.searchParams;
    delete facetParams.limit; // This would be set as a default from product api
    facetParams.filterParams = makeFilterParamsObject(filterList, filterParamsSchema);
    dispatch({ type: actionTypes.toggleFacetsLoader, payload: true });
    return post(facetsUrl, facetParams)
        .then((facets) => {
            dispatch({ type: actionTypes.updateFacets, payload: facets });
            dispatch({ type: actionTypes.toggleFacetsLoader, payload: false });
        })
        .catch((err) => {
            if (getState().router.isBrowser) {
                Sentry.captureException(err, { extra: { ...facetParams, facetsUrl } });
            }
            dispatch({ type: actionTypes.toggleFacetsLoader, payload: false });
        });
};

export const fetchAllFacets = () => (dispatch, getState): Promise<any> => {
    const facetsUrl = `${getState().pageData.appConfig.apiHost}/cruises/facets`;
    return post(facetsUrl, {})
        .then((facets) => {
            dispatch({ type: actionTypes.updateFacetsAll, payload: facets });
        })
        .catch((err) => {
            if (getState().router.isBrowser) {
                Sentry.captureException(err, { extra: { facetsUrl } });
            }
        });
};

export const fetchAllSearchBoxFacets = () => (dispatch, getState): Promise<any> => {
    const facetsUrl = `${getState().pageData.appConfig.apiHost}/cruises/allfacetssearchbox`;
    return get(facetsUrl)
        .then((facets) => {
            dispatch({ type: actionTypes.updateFacets, payload: facets });
        })
        .catch((err) => {
            if (getState().router.isBrowser) {
                Sentry.captureException(err, { extra: { facetsUrl } });
            }
        });
};

export const setSortOrder = (sortOrder: string) => ({
    type: actionTypes.setSortOrder,
    payload: sortOrder,
});

export const newSearch = (queryString: string) => (dispatch, getState) => {
    const state = getState();
    const searchUrl = `${state.pageData.appConfig.apiHost}/cruises/search-with-aggregation`;
    const isDesktopDevice = state.pageData.deviceType === 'desktop';
    const facetsUrl = `${state.pageData.appConfig.apiHost}/cruises/facets`;

    const searchParamsObject: ICruiseSearchApiObject = queryString
        ? qs.parse(queryString.replace('?', ''), { arrayLimit: 100 })
        : {};

    if (searchParamsObject?.filterParams?.inclFlight) {
        const { inclFlight } = searchParamsObject.filterParams;
        if (typeof inclFlight === 'string') {
            searchParamsObject.filterParams.inclFlight = isNaN(inclFlight)
                ? String(inclFlight).toLowerCase() === 'true'
                : Number(inclFlight) > 0;
        }
    }

    if (searchParamsObject?.filterParams?.hasTvOffer) {
        const { hasTvOffer } = searchParamsObject.filterParams;
        if (typeof hasTvOffer === 'string') {
            searchParamsObject.filterParams.hasTvOffer = isNaN(hasTvOffer)
                ? String(hasTvOffer).toLowerCase() === 'true'
                : Number(hasTvOffer) > 0;
        }
    }

    const offset =
        searchParamsObject.offset && isDesktopDevice
            ? parseInt((searchParamsObject.offset as any) as string, 10)
            : 0;
    const limit =
        searchParamsObject.limit && isDesktopDevice
            ? parseInt((searchParamsObject.limit as any) as string, 10)
            : 20;
    const priceMin =
        searchParamsObject?.filterParams?.priceMin || state?.pageData?.brandConfig?.priceMin || 0;

    dispatch({ type: actionTypes.toggleSearchLoader, payload: true });
    dispatch({ type: actionTypes.toggleFacetsLoader, payload: true });

    const { countryCode } = state?.pageData?.appConfig || (Markets.de as ICountryCode);
    const newSearchParams: ICruiseSearchApiObject = {
        ...defaultSearchParams,
        filterParams: {
            ...searchParamsObject.filterParams,
            priceMin,
        },
        sortString:
            searchParamsObject.sortString || getSortingBasedOnMarket({ market: countryCode }),
        offset,
        limit,
        priorityOperator: offset === 0 ? state?.pageData.brandConfig.priorityOperatorNid : null,
    };

    const newFacetParams = deepClone(newSearchParams);

    delete newFacetParams.limit; // This would be set as a default from product api

    return Promise.all([
        post(searchUrl, newSearchParams),
        post(facetsUrl, newFacetParams),
        post(facetsUrl, {}),
        [],
    ])
        .then(([search, facets, facetsAll]) => {
            const filterParamsList = makeFilterParamsList(
                searchParamsObject.filterParams,
                filterParamsSchema,
            );

            dispatch({
                type: actionTypes.newSearch,
                payload: {
                    data: search,
                    searchParams: newSearchParams,
                    filterParamsList,
                    filterParamsListActive: filterParamsList,
                    searchFacets: { ...facets },
                    queryString: queryString,
                    maxPrice: search.maxPrice,
                    maxDiscount: search.maxDiscount,
                    facetsAll,
                },
            });

            dispatch({ type: actionTypes.toggleSearchLoader, payload: false });
            dispatch({ type: actionTypes.toggleFacetsLoader, payload: false });
        })
        .catch((error) => {
            dispatch({ type: actionTypes.toggleFacetsLoader, payload: false });
            dispatch({ type: actionTypes.toggleSearchLoader, payload: false });
            throw error;
        });
};

export const newFacetsSearch = (queryString: string) => (dispatch, getState) => {
    const state = getState();
    const isDesktopDevice = state.pageData.deviceType === 'desktop';
    const facetsUrlWithNum = `${state.pageData.appConfig.apiHost}/cruises/facets`;

    const searchParamsObject: ICruiseSearchApiObject = queryString
        ? qs.parse(queryString.replace('?', ''), { arrayLimit: 100 })
        : {};

    if (searchParamsObject?.filterParams?.inclFlight) {
        const { inclFlight } = searchParamsObject.filterParams;
        if (typeof inclFlight === 'string') {
            searchParamsObject.filterParams.inclFlight = isNaN(inclFlight)
                ? String(inclFlight).toLowerCase() === 'true'
                : Number(inclFlight) > 0;
        }
    }

    if (searchParamsObject?.filterParams?.hasTvOffer) {
        const { hasTvOffer } = searchParamsObject.filterParams;
        if (typeof hasTvOffer === 'string') {
            searchParamsObject.filterParams.hasTvOffer = isNaN(hasTvOffer)
                ? String(hasTvOffer).toLowerCase() === 'true'
                : Number(hasTvOffer) > 0;
        }
    }

    const offset =
        searchParamsObject.offset && isDesktopDevice
            ? parseInt((searchParamsObject.offset as any) as string, 10)
            : 0;
    const limit =
        searchParamsObject.limit && isDesktopDevice
            ? parseInt((searchParamsObject.limit as any) as string, 10)
            : 20;

    const priceMin =
        searchParamsObject?.filterParams?.priceMin || state?.pageData?.brandConfig?.priceMin || 0;

    dispatch({ type: actionTypes.toggleFacetsLoader, payload: true });

    const { countryCode } = state?.pageData?.appConfig || (Markets.de as ICountryCode);

    const newSearchParams: ICruiseSearchApiObject = {
        ...defaultSearchParams,
        filterParams: { ...searchParamsObject.filterParams, priceMin },
        sortString:
            searchParamsObject.sortString || getSortingBasedOnMarket({ market: countryCode }),
        offset,
        limit,
        priorityOperator: offset === 0 ? state?.pageData.brandConfig.priorityOperatorNid : null,
    };

    const newFacetParams = deepClone(newSearchParams);

    delete newFacetParams.limit; // This would be set as a default from product api

    return Promise.resolve([post(facetsUrlWithNum, newFacetParams)])
        .then(([facetsAll]) => {
            dispatch({
                type: actionTypes.newFacetsSearch,
                payload: {
                    facetsAll,
                },
            });
            dispatch({ type: actionTypes.toggleFacetsLoader, payload: false });
        })
        .catch((error) => {
            dispatch({ type: actionTypes.toggleFacetsLoader, payload: false });
            throw error;
        });
};

export const loadMore = (page?: number) => (dispatch, getState) => {
    return new Promise((resolve: (value: boolean) => void) => {
        if (
            getState().search.searchResults.cruises.length <
            getState().search.searchResults.numResults
        ) {
            if (!isDesktop()) {
                dispatch({ type: actionTypes.appendNextResults });
            }
            dispatch({ type: actionTypes.toggleSearchLoader, payload: true });
            const history = getState().router.history;
            const url = `${getState().pageData.appConfig.apiHost}/cruises/search`;
            const searchParams = getState().search.searchParams;

            const newOffset = page ? page * 10 - 10 : searchParams.offset + searchParams.limit;
            searchParams.limit = 10;

            const newSearchParams = { ...searchParams, offset: newOffset };
            newSearchParams.filterParams = makeFilterParamsObject(
                getState().search.filterParamsListActive,
                filterParamsSchema,
            );
            if (isDesktop()) {
                const searchSlug = getState().pageData.brandConfig.slugsObj.searchresults;

                let currentLocation = getState().router.location;
                currentLocation.pathname = `/${searchSlug}`;

                const searchParams = { ...newSearchParams };
                const searchString = qs.stringify({ ...searchParams });
                const newLocation = { ...currentLocation, search: `?${searchString}` };

                history.push(newLocation);
                return resolve(false);
            }

            return post(url, newSearchParams)
                .then((response) => {
                    dispatch({
                        type: actionTypes.loadMore,
                        payload: {
                            data: response,
                            searchParams: newSearchParams,
                        },
                    });

                    dispatch({ type: actionTypes.toggleSearchLoader, payload: false });
                    if (getState().search.appendAfterLoad) {
                        dispatch({ type: actionTypes.appendNextResults });
                    }
                    resolve(true);
                })
                .catch((_) => {
                    dispatch({ type: actionTypes.toggleSearchLoader, payload: false });
                    resolve(false);
                }) as any;
        } else {
            resolve(false);
        }
    });
};

export const toggleSearchFilter = () => (dispatch, getState) => {
    dispatch({
        type: actionTypes.openSearchFilter,
    });
};

export const loadTopSearchResults = () => (dispatch, getState) => {
    const url = `${getState().pageData.appConfig.apiHost}/cruises/search`;
    const searchParams: ICruiseSearchApiObject = {
        ...defaultSearchParams,
        filterParams: {
            banderoles: [331893],
        },
        limit: 3,
    };
    return post(url, searchParams).then((topResults) => {
        dispatch({ type: actionTypes.loadTopSearchResults, payload: topResults });
    });
};

export const loadSearchResults = (filterParams, limit) => (dispatch, getState) => {
    dispatch({ type: actionTypes.toggleSearchLoader, payload: true });
    const url = `${getState().pageData.appConfig.apiHost}/cruises/search`;
    const searchParams: ICruiseSearchApiObject = {
        ...defaultSearchParams,
        filterParams: filterParams,
        limit: limit,
    };
    return post(url, searchParams)
        .then((results) => {
            dispatch({ type: actionTypes.loadSearchResults, payload: results });
            dispatch({ type: actionTypes.toggleSearchLoader, payload: false });
        })
        .catch((_) => {
            dispatch({ type: actionTypes.toggleSearchLoader, payload: false });
        });
};

export const setLoadingState = (isLoading: boolean) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.toggleSearchLoader,
        payload: isLoading,
    });
};

/**
 *  does a new search by changing the URL...
 */
export const doNewSearchViaUrl = () => (dispatch, getState) => {
    const filterParamsList = getState().search.filterParamsList;
    const oldSearchApiObject = getState().search.searchParams;
    const history = getState().router.history;
    const filterParamsObject = makeFilterParamsObject(filterParamsList, filterParamsSchema);
    let currentLocation = getState().router.location;

    if (currentLocation.pathname === '') {
        const searchSlug = getState().pageData.brandConfig.slugsObj.searchresults;
        currentLocation.pathname = `/${searchSlug}`;
    }

    const searchApiObject = { ...oldSearchApiObject, filterParams: filterParamsObject };
    const { filterParams, sortString } = searchApiObject;

    const newSearchApiObject = {
        filterParams,
        sortString: sortString,
    };

    const searchString = qs.stringify(newSearchApiObject);
    const newLocation = { ...currentLocation, search: `?${searchString}` };

    if (newLocation.search !== history.location.search) {
        dispatch({ type: actionTypes.clearSearchResults, payload: null });
    }
    history.push(newLocation);
    dispatch(toggleSearchOverlay(false, 'apply'));
};
export const toggleSearchOverlay = (value: boolean, action: searchOverlayAction) => (
    dispatch,
    getState,
) => {
    dispatch({
        type: actionTypes.toggleSearchOverlay,
        payload: { value, action },
    });
};
export const cancelSearch = () => (dispatch, getState: () => IState) => {
    dispatch(clearFilterParams(false));

    dispatch(setFilterParam(null, false));
    dispatch(toggleSearchOverlay(false, 'cancel'));
};
