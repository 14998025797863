import * as React from 'react';
import styled from '@emotion/styled';
import { useState } from 'react';
import { __ } from '../../helpers/TranslationService';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize, { IViewPortSize } from '../../hooks/useViewport';
import { isDesktop as isBrowserDesktop } from '../../helpers/responsiveHelpers/responsiveHelpers';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import RecommendationZones from '../../components/RecommendationZones/RecommendationZones';
import { SeoText } from '../../components/SEOSection/SEOSection';
import { PromoBannerWrapper } from '../Landing/LandingPage';
import { PromoBanner } from '../../components/PromoBanner/PromoBanner';

const HeroSection = styled.div<{ backgroundImage: string }>`
    position: relative;
    padding: 24px 16px;
    background-color: ${({ theme }) => theme.colors.imperialBlue[100]};
    @media (min-width: ${BreakpointsEnum.sm}) {
        padding: 50px 0;
        background-image: url(${({ backgroundImage }) => backgroundImage});
        background-position: center;
        background-size: cover;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
                to bottom,
                ${({ theme }) => theme.colors.cetaceanBlue[30]},
                ${({ theme }) => theme.colors.cetaceanBlue[100]}
            );
        }
    }
`;

const HeroSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (min-width: ${BreakpointsEnum.sm}) {
        flex-direction: row;
        justify-content: space-between;
        margin: 0 32px;
        gap: 0;
    }
`;

const HeroSectionContent = styled.div`
    text-align: center;
    @media (min-width: ${BreakpointsEnum.sm}) {
        text-align: left;
        width: 40%;
        align-content: center;
    }
`;

const HeroSectionMedia = styled.div`
    @media (min-width: ${BreakpointsEnum.sm}) {
        width: 50%;
    }
`;

const ContainerWrapper = styled.div`
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    @media (min-width: ${BreakpointsEnum.md}) {
        width: 970px;
    }
    @media (min-width: ${BreakpointsEnum.lg}) {
        width: 1170px;
    }
`;

const HeroSectionContainer = styled(ContainerWrapper)`
    position: relative;
    z-index: 5;
`;

const HeroSectionTitle = styled.h1`
    color: ${({ theme }) => theme.colors.white[100]};
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 8px;
    @media (min-width: ${BreakpointsEnum.sm}) {
        font-size: 44px;
        line-height: 50px;
    }
`;

const HeroSectionDescription = styled.div`
    color: ${({ theme }) => theme.colors.white[100]};
    font-size: 14px;
    line-height: 22px;
    p {
        margin: 0;
    }
`;

const RecommendationZonesStyled = styled(RecommendationZones)`
    @media (min-width: ${BreakpointsEnum.sm}) {
        margin-top: 80px;
        margin-bottom: 120px;
    }
`;

const HeadlineStyled = styled.h2`
    font-size: 25px;
    font-weight: 700;
    margin-left: 16px;
    margin-right: 16px;
`;

const SeoTextStyled = styled(SeoText)`
    margin-top: 24px;
`;

const MediaOfferPage = () => {
    const storeState = useTypedSelector((state) => state);
    const { mediaOfferPage } = storeState;

    const { width }: IViewPortSize = useWindowSize();
    const isDesktop = isBrowserDesktop(width);

    return (
        <>
            <HeroSection backgroundImage={mediaOfferPage.headerImage}>
                <HeroSectionContainer>
                    <HeroSectionWrapper>
                        <HeroSectionContent>
                            <HeroSectionTitle>{mediaOfferPage.headerTitle}</HeroSectionTitle>
                            <HeroSectionDescription
                                dangerouslySetInnerHTML={{
                                    __html: mediaOfferPage.headerDescription,
                                }}
                            />
                        </HeroSectionContent>
                        {mediaOfferPage.headerYoutubeMediaUrl && (
                            <HeroSectionMedia>
                                <iframe
                                    title="tv-stream"
                                    id="live-vid-stream"
                                    src={mediaOfferPage.headerYoutubeMediaUrl}
                                    width="100%"
                                    height={isDesktop ? 320 : 194}
                                    frameBorder="none"
                                    scrolling="auto"
                                />
                            </HeroSectionMedia>
                        )}
                    </HeroSectionWrapper>
                </HeroSectionContainer>
            </HeroSection>
            <ContainerWrapper>
                {mediaOfferPage.promoBanner && (
                    <div className="row">
                        <PromoBannerWrapper>
                            <PromoBanner
                                title={mediaOfferPage.promoBanner.title}
                                description={mediaOfferPage.promoBanner.description}
                                subTitle={mediaOfferPage.promoBanner?.subTitle}
                                imageUrl={mediaOfferPage.promoBanner?.imageUrl}
                                buttonText={mediaOfferPage.promoBanner?.button?.text}
                                buttonUrl={mediaOfferPage.promoBanner?.button?.url}
                            />
                        </PromoBannerWrapper>
                    </div>
                )}
                <RecommendationZonesStyled
                    title={mediaOfferPage.title}
                    data={mediaOfferPage.zoneTabs}
                />
                {mediaOfferPage.headline && (
                    <HeadlineStyled>{mediaOfferPage.headline}</HeadlineStyled>
                )}
                {mediaOfferPage.seoTextBottom && (
                    <SeoTextStyled
                        dangerouslySetInnerHTML={{
                            __html: mediaOfferPage.seoTextBottom,
                        }}
                    />
                )}
            </ContainerWrapper>
        </>
    );
};
export default MediaOfferPage;
