import {
    getHttpContext,
    getQueryParams,
    IsomorphicHelpers,
} from '../../../../../helpers/IsomorphicHelpers';

export const getCabinKindFromUrl = () => {
    const query = getQueryParams();

    return query?.filterParams?.cabinKind || [];
};

export const setSearchResultQueryParamsToUrl = (baseUrl, selectedSail, cabinKind) => {
    const isServerSide = IsomorphicHelpers.isServerSide;
    const httpContext = getHttpContext();

    const currentOrigin = isServerSide
        ? `${httpContext.request.protocol || 'https'}://${httpContext.request.headers.host}`
        : location.origin;

    const fullUrl = baseUrl.startsWith('http') ? baseUrl : `${currentOrigin}${baseUrl}`;
    const url = new URL(fullUrl);
    url.searchParams.set('selectedSail', selectedSail);

    cabinKind.forEach((id, index) => {
        url.searchParams.append(`filterParams[cabinKind][${index}]`, id.toString());
    });

    return url.toString();
};
