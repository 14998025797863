import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { config } from '../../../../config';
import { BreakpointsEnum } from '../../../../helpers/breakpoints';
import { PackageLabelIcon } from '../../../../components/Icons/PackageLabelIcon';
import { InfoTransparentIcon } from '../../../../components/Icons/InfoTransparentIcon';
import { Modal } from '../../../../components/Modal';
import Link from '../../../../components/Link';
import { MarkAsFavorite } from '../../../../components/MarkAsFavorite';

export const C1Background = (theme) =>
    css`
        background: url(${config.images}/common/c1_package_desktop_bg.svg) right center no-repeat,
            linear-gradient(
                to right,
                ${theme.colors.darkCornFlower[100]},
                ${theme.colors.imperialBlue[100]}
            );
        @media (max-width: ${BreakpointsEnum.sm}) {
            background: url(${config.images}/common/c1_package_desktop_bg.svg) right -100px center,
                linear-gradient(
                    to right,
                    ${theme.colors.darkCornFlower[100]},
                    ${theme.colors.imperialBlue[100]}
                );
        }
    `;
export const DefaultBackground = css`
    background-image: url(${config.images}/common/dl_package_desktop_bg.svg);
`;

export const PackageLabelWrapper = styled.div<{ isCruise1st: boolean }>`
    background-repeat: no-repeat;
    background-color: ${({ theme }) => theme.background.brandColor1};
    background-position: right center;
    min-height: 49px;
    border-top-left-radius: ${({ theme }) => theme.container.borderRadius};
    border-top-right-radius: ${({ theme }) => theme.container.borderRadius};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    ${({ isCruise1st, theme }) => (isCruise1st ? C1Background(theme) : DefaultBackground)}
`;

export const PackageLabelText = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white[100]};
    span {
        font-weight: 700;
        margin-right: 5px;
    }
`;

export const PackageLabelButton = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white[100]};
    cursor: pointer;
    span {
        display: none;
    }
    @media (min-width: ${BreakpointsEnum.sm}) {
        span {
            display: inline-block;
        }
    }
`;

export const PackageLabelIconStyled = styled(PackageLabelIcon)`
    margin: -1px 10px;
`;

export const InfoTransparentIconStyled = styled(InfoTransparentIcon)`
    margin: -1px 15px 0 10px;
`;

export const StyledInfoModal = styled(Modal)`
    .modalContent {
        max-width: 550px;
    }
`;

export const StyledOnBoardLabel = styled.p`
    background: ${(props) => props.theme.alert.succeed.background};
    border: 2px solid ${(props) => props.theme.alert.succeed.border};
    border-radius: ${({ theme }) => theme.container.borderRadius};
    padding: 6px 12px;
    font-size: 12px;
    font-weight: 700;
    margin: 0 16px 8px 16px;
    align-self: flex-start;
`;

export const StyledAdultOnlyCruiseLabel = styled.p`
    background: ${(props) => props.theme.alert.attention.background};
    border: 2px solid ${(props) => props.theme.alert.attention.border};
    border-radius: ${({ theme }) => theme.container.borderRadius};
    padding: 6px 12px;
    font-size: 12px;
    font-weight: 700;
    margin: 0 16px 8px 16px;
    align-self: flex-start;
`;

export const StyledInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow: hidden;
`;

export const StyledSearchItemContent = styled(Link)<{ isPackage?: boolean }>`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: ${(props) => (props.isPackage ? '0 0 6px 6px' : '6px')};
    overflow: hidden;
    cursor: pointer;
    padding-bottom: 16px;

    &:hover {
        color: inherit;
    }
`;

export const ImageStyled = styled.img`
    object-fit: cover;
    height: inherit;
    width: inherit;

    &.routeImage {
        @media (min-width: ${BreakpointsEnum.xss}) {
            object-fit: fill;
        }
        @media (min-width: ${BreakpointsEnum.xs}) {
            object-fit: cover;
        }
    }
`;

export const CruiseImageContainer = styled.div<{ isPackage?: boolean }>`
    position: relative;
    flex: 0 0 50%;
    overflow: hidden;
    border-radius: ${(props) => (props.isPackage ? '0' : '6px 6px 0 0')};
    height: auto;

    @media (min-width: ${BreakpointsEnum.md}) {
        border-radius: ${({ theme }) => theme.container.borderRadius};
    }

    ${ImageStyled} {
        width: 100%;

        @media (min-width: ${BreakpointsEnum.md}) {
            border-radius: ${({ theme }) => theme.container.borderRadius};
        }
    }
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;

    @media (min-width: ${BreakpointsEnum.md}) {
        flex-direction: row;
        padding: 0 16px;

        ${CruiseImageContainer} {
            max-width: 50%;
            border-radius: ${({ theme }) => theme.container.borderRadius};
        }
    }
`;

export const StyledFavoriteBtn = styled(MarkAsFavorite)`
    position: absolute;
    right: 6px;
    top: 6px;

    @media (min-width: ${BreakpointsEnum.md}) {
        left: 12px;
        top: 12px;
        right: initial;
    }
`;
